/** Headers for the published data table */
export enum PublishedDataTableHeaders {
  /** Status of the entity */
  status = "Status",

  /** User who created the entity */
  createdBy = "Created by",

  /** The date when the entity was created */
  createdAt = "Created at",

  /** User who last updated the entity */
  updatedBy = "Updated by",

  /** The date when the entity was last updated */
  updatedAt = "Update date",

  /** Action buttons or additional info to show */
  actions = "Actions",
}
