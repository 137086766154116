import {
  CaptureTreeEntity,
  CaptureTreeEntityType,
} from "@faro-lotv/service-wires";

/** Headers for the raw scans table */
export enum RawScansTableHeaders {
  /** Entity name */
  name = "Name",

  /** Path to the cluster where the entity is located */
  clusterPath = "Cluster path",

  /** User who created the entity */
  createdBy = "Created by",

  /** The date time when the entity was created */
  createdAt = "Created at",

  /** Type of device used to generate the entity */
  deviceType = "Device",

  /** Where the user will see the spinner loading and the message */
  actions = "Actions",
}

/** Possible device types used to generate the raw data */
export enum DeviceType {
  blink = "Blink",
  focus = "Focus",
  orbis = "Orbis",
  unknown = "Unknown",
}

/** Raw scan entity type */
export interface RawScan extends CaptureTreeEntity {
  /** Path to the cluster where the entity is located */
  clusterPath: string;

  /** Whether the scan is still processing */
  isProcessing: boolean;
}

/** List of valid capture tree entities of type scan */
export const CAPTURE_TREE_ENTITY_SCAN_TYPES: CaptureTreeEntityType[] = [
  CaptureTreeEntityType.elsScan,
  CaptureTreeEntityType.focusScan,
  CaptureTreeEntityType.orbisScan,
  CaptureTreeEntityType.pCloudUploadScan,
] as const;
