import { useMediaQueryList } from "@hooks/use-media-query";
import { useAppDispatch } from "@store/store-helper";
import { FaroTable } from "@components/common/faro-table/faro-table";
import { useEffect } from "react";
import { resetTableState, setTableType } from "@store/table/table-slice";
import { TableType } from "@utils/track-event/track-event-list";
import { Team } from "@store/teams/teams-types";
import { getTeamsColumns } from "@components/table/teams/teams-columns";
import { GroupBulkButtonComponents } from "@components/common/faro-table/faro-table-types";
import { TeamsHeaders } from "@components/table/teams/teams-table-types";
import { useDateTime } from "@hooks/use-date-time";

interface Props {
  /** The teams that is shown in the table */
  teams: Team[];

  /** Whether the data for showing a table is loading or not */
  isLoading: boolean;

  /** All the columns that we want to show. The order defines the order of the columns */
  requiredColumns: TeamsHeaders[];

  /** List of all the bulk actions and callbacks */
  bulkActionButtons?: GroupBulkButtonComponents;
}

/** Listing all the teams of a company */
export function TeamsTable({
  teams,
  isLoading,
  requiredColumns,
  bulkActionButtons,
}: Props): JSX.Element {
  const dispatch = useAppDispatch();
  const { formatDate } = useDateTime();
  const { isMedium, isScreenLgAndLarger } = useMediaQueryList();

  // Make sure the correct table type is set in the store and reset the bulk action state after unmounting
  useEffect(() => {
    dispatch(setTableType(TableType.teams));

    return () => {
      dispatch(resetTableState());
    };
  }, [dispatch]);

  return (
    <FaroTable
      entities={teams}
      uniqueIdKey={"id"}
      isLoading={isLoading}
      columns={requiredColumns.map(
        (requiredColumn) =>
          getTeamsColumns({
            options: {
              isScreenLgOrLarger: isScreenLgAndLarger,
              isMedium,
            },
            formatDate,
          })[requiredColumn]
      )}
      bulkActionButtons={bulkActionButtons}
      rowHeight={70}
    />
  );
}
