import { useToast } from "@hooks/use-toast";
import { FaroButtonContained } from "@components/common/faro-button-contained";
import { BaseTeamProps } from "@store/teams/teams-types";

/** Renders the invite member button and the functionality in team page */
export function InviteMemberToTeam({ team }: BaseTeamProps): JSX.Element {
  const { showToast } = useToast();

  /** Triggers when user clicks on the invite button */
  function onInviteClick(): void {
    showToast({
      type: "warning",
      message: "Coming soon!",
      description:
        "This functionality is still under development and will be available soon",
    });
  }

  return (
    <FaroButtonContained onClick={onInviteClick}>
      Invite members
    </FaroButtonContained>
  );
}
