import { useDataManagementContext } from "@context-providers/data-management/data-management-context";
import { Box } from "@mui/material";
import { DataManagementEvents } from "@utils/track-event/track-event-list";
import { OpenDataPreparationButton } from "@pages/project-details/project-data-management/open-data-preparation-button";
import { GUID } from "@faro-lotv/foundation";

interface Props {
  /** ID of the registration */
  registrationId: GUID;
}

/** Renders published data actions column */
export function PublishedDataActions({
  registrationId,
}: Props): JSX.Element | null {
  const { projectId } = useDataManagementContext();

  return (
    <Box data-testid="published-data-actions-container">
      <OpenDataPreparationButton
        projectId={projectId}
        registrationId={registrationId}
        eventName={DataManagementEvents.openDataManagementInspect}
        label="Inspect"
      />
    </Box>
  );
}
