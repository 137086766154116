import { FaroButton } from "@components/common/faro-button";
import { sphereColors } from "@styles/common-colors";
import { getInspectAndPublishToolUrl } from "@pages/project-details/project-data-management/prepared-data/prepared-data-utils";
import { GUID } from "@faro-lotv/foundation";
import { BaseProjectIdProps } from "@custom-types/sdb-company-types";
import { OPEN_PROJECT_TARGET_ATTRIBUTE } from "@utils/project-utils";
import { useTrackEvent } from "@utils/track-event/use-track-event";
import { DataManagementEvents } from "@utils/track-event/track-event-list";
import { withEllipsis } from "@styles/common-styles";
import { Box } from "@mui/material";

interface Props extends BaseProjectIdProps {
  /** Id of the registration */
  registrationId: GUID;

  /** Data management event to track when opening the tool */
  eventName: DataManagementEvents;

  /** Label of the button */
  label: string;
}

/** Button to open the data preparation tool for the passed project and registration */
export function OpenDataPreparationButton({
  projectId,
  registrationId,
  eventName,
  label,
}: Props): JSX.Element {
  const { trackEvent } = useTrackEvent();

  return (
    <FaroButton
      sx={{
        maxWidth: "160px",
        fontSize: "12px",
        color: sphereColors.blue500,
        textTransform: "none",
        border: "none",
        backgroundColor: "unset",
        "&:hover": {
          backgroundColor: "unset",
        },
        "&:active": {
          backgroundColor: "unset",
        },
      }}
      to={getInspectAndPublishToolUrl(projectId, registrationId)}
      target={OPEN_PROJECT_TARGET_ATTRIBUTE}
      onClick={() => {
        trackEvent({
          name: eventName,
          props: {
            projectId,
            registrationId,
          },
        });
      }}
    >
      <Box
        sx={{
          ...withEllipsis,
        }}
      >
        {label}
      </Box>
    </FaroButton>
  );
}
