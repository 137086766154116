import { useAppParams } from "@router/router-helper";
import { NotFoundPage } from "@pages/not-found-page";
import { BaseTeamProps } from "@store/teams/teams-types";
import { EmptyPage } from "@components/common/empty-page/empty-page";
import ComingSoonIcon from "@assets/icons/new/coming-soon_60px.svg?react";
import { TeamDetailsInfoBar } from "@pages/members/teams/team-details-info-bar";

interface Props extends Partial<BaseTeamProps> {
  /** Flag whether the content should be shown as skeletons because it is still loading */
  isLoading?: boolean;
}

/**
 * Contains the Members tab of the team details
 */
export function TeamMembers({ team, isLoading = false }: Props): JSX.Element {
  const { companyId } = useAppParams();

  if (!companyId) {
    return <NotFoundPage />;
  }

  return (
    <>
      <TeamDetailsInfoBar team={team} isLoading={isLoading} />

      <EmptyPage
        title="Coming soon"
        subtitle="This page is still under development and will be available soon"
        icon={ComingSoonIcon}
      />
    </>
  );
}
