import { PageInfoBar } from "@components/common/page-info-bar";
import { FaroTextField } from "@components/common/faro-text-field/faro-text-field";
import { useAppSelector } from "@store/store-helper";
import { teamsSelector } from "@store/teams/teams-selector";
import { CreateTeam } from "@pages/members/teams/create-team";

/**
 * Shows the main details for the user groups
 */
export function TeamsDetails(): JSX.Element {
  const teams = useAppSelector(teamsSelector);

  return (
    <PageInfoBar
      items={[
        {
          content: (
            <FaroTextField
              label="Total groups"
              initialValue={teams.length}
              faroVariant="main"
              fontSize="18px"
            />
          ),
        },
      ]}
      action={<CreateTeam />}
    />
  );
}
