import { FaroTable } from "@components/common/faro-table/faro-table";
import { RegistrationRevision } from "@faro-lotv/service-wires";
import { fetchingMembersFlagsSelector } from "@store/members/members-selector";
import { fetchingProjectsFlagsSelector } from "@store/projects/projects-selector";
import { useAppSelector } from "@store/store-helper";
import { useMemo } from "react";
import { getPublishedDataTableColumns } from "@pages/project-details/project-data-management/published-data/published-data-table-utils";
import { PublishedDataTableHeaders } from "@pages/project-details/project-data-management/published-data/published-data-types";
import { useDataManagementContext } from "@context-providers/data-management/data-management-context";
import { useDateTime } from "@hooks/use-date-time";

/** The columns to display in the table */
const COLUMNS: PublishedDataTableHeaders[] = [
  PublishedDataTableHeaders.status,
  PublishedDataTableHeaders.createdBy,
  PublishedDataTableHeaders.createdAt,
  PublishedDataTableHeaders.actions,
];

interface Props {
  /** List of published data entities */
  publishedData: RegistrationRevision[];

  /** Whether the published data is being fetched for the first time */
  isFetchingForTheFirstTime: boolean;
}

/** Renders the table that displays published data */
export function PublishedDataTable({
  publishedData,
  isFetchingForTheFirstTime,
}: Props): JSX.Element {
  const { companyMembers, projectMembers } = useDataManagementContext();

  const { isFetchingCompanyMembers } = useAppSelector(
    fetchingMembersFlagsSelector
  );
  const { isFetchingProjects: isFetchingProjectMembers } = useAppSelector(
    fetchingProjectsFlagsSelector
  );

  const { formatDate } = useDateTime();

  const isLoading = useMemo(() => {
    return (
      isFetchingForTheFirstTime ||
      isFetchingCompanyMembers ||
      isFetchingProjectMembers
    );
  }, [
    isFetchingForTheFirstTime,
    isFetchingCompanyMembers,
    isFetchingProjectMembers,
  ]);

  return (
    <FaroTable
      entities={publishedData}
      uniqueIdKey={"id"}
      isLoading={isLoading}
      columns={COLUMNS.map(
        (column) =>
          getPublishedDataTableColumns({
            companyMembers,
            projectMembers,
            formatDate,
          })[column]
      )}
      rowHeight={54}
      shouldHideCheckbox={true}
    />
  );
}
