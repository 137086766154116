import { useDataManagementContext } from "@context-providers/data-management/data-management-context";
import { SdbRegistration } from "@custom-types/project-data-management-types";
import { Box } from "@mui/material";
import { isRegisteredRegistrationRevision } from "@pages/project-details/project-data-management/prepared-data/prepared-data-utils";
import { withEllipsis } from "@styles/common-styles";
import { DataManagementEvents } from "@utils/track-event/track-event-list";
import { SHOW_ON_HOVER_CLASS } from "@utils/ui-utils";
import { OpenDataPreparationButton } from "@pages/project-details/project-data-management/open-data-preparation-button";

interface Props {
  /** Registration */
  registration: SdbRegistration;
}

/** Renders prepared data actions column */
export function PreparedDataActions({
  registration,
}: Props): JSX.Element | null {
  const { projectId } = useDataManagementContext();

  if (isRegisteredRegistrationRevision(registration)) {
    return (
      <Box
        data-testid="prepared-data-actions-container"
        sx={{
          visibility: "hidden",
          cursor: "pointer",
          ...withEllipsis,
        }}
        className={SHOW_ON_HOVER_CLASS}
      >
        <OpenDataPreparationButton
          projectId={projectId}
          registrationId={registration.id}
          eventName={DataManagementEvents.openDataManagementInspectPublish}
          label="Inspect & publish"
        />
      </Box>
    );
  }

  return null;
}
