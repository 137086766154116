import { SphereTooltip } from "@components/common/sphere-tooltip";
import { SHOW_ON_HOVER_CLASS } from "@utils/ui-utils";
import DeleteIcon from "@assets/icons/new/delete_32px.svg?react";
import { FaroIconButton } from "@components/common/faro-icon-button";
import { Box, Link } from "@mui/material";
import { sphereColors } from "@styles/common-colors";
import { useTrackEvent } from "@utils/track-event/use-track-event";
import { TeamEvents } from "@utils/track-event/track-event-list";
import { SphereActionDivider } from "@components/common/sphere-action-divider";
import { useAppNavigation } from "@hooks/use-app-navigation";
import { useAppParams } from "@router/router-helper";
import { Team } from "@store/teams/teams-types";

interface Props {
  /** The team data */
  team: Team;
}

/** Render team table action elements */
export function TeamsTableAction({ team }: Props): JSX.Element {
  const { trackEvent } = useTrackEvent();
  const { navigateToTeamDetails } = useAppNavigation();
  const { companyId } = useAppParams();

  async function onViewDetailsClick(): Promise<void> {
    trackEvent({
      name: TeamEvents.openTeam,
      props: {
        teamId: team.id,
        numberOfMembers: team.sampleMembers.length,
      },
    });

    // Navigate to team details page
    if (companyId) {
      navigateToTeamDetails({
        companyId,
        teamId: team.id,
      });
    }
  }

  return (
    <Box
      className={SHOW_ON_HOVER_CLASS}
      sx={{
        display: "flex",
        alignItems: "center",
        gap: 1,
        // This will be overridden by parent component using SHOW_ON_HOVER_CLASS
        visibility: "hidden",
      }}
    >
      {/* View details link */}
      <Link
        onClick={onViewDetailsClick}
        underline="always"
        sx={{
          marginLeft: "10px",
          color: sphereColors.blue500,
          textDecorationColor: sphereColors.blue500,
          cursor: "pointer",
        }}
      >
        View details
      </Link>

      <SphereActionDivider />

      {/* Delete group button */}
      <SphereTooltip title={"Delete group"}>
        <FaroIconButton component={DeleteIcon} iconSize="18px" />
      </SphereTooltip>
    </Box>
  );
}
